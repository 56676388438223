/* import __COLOCATED_TEMPLATE__ from './article-suggestions.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { tracked } from '@glimmer/tracking';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { action } from '@ember/object';
import type ArticleContent from 'embercom/models/articles/article-content';
import type ArticleMultilingual from 'embercom/models/article-multilingual';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  activeContent: ArticleContent;
}

export default class ArticleSuggestions extends Component<Args> {
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked article: ArticleMultilingual;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.article = this.args.activeContent.article;
  }

  @action async toggleExcludeFromHomeScreenSuggestions() {
    this.article.excludeFromHomeScreenSuggestions = !this.article.excludeFromHomeScreenSuggestions;
    try {
      await taskFor(this.article.updateSettingsMultiple).perform();
      await this.article.reload();
      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.editor.side-drawer.settings.article-settings-updated'),
      );
      this.knowledgeHubEditorService.trackAnalyticsEvent(
        'toggled_exclude_from_home_screen_suggestions',
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('articles.editor.side-drawer.settings.problem-in-saving-settings'),
      );
      throw error;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::SidePanel::HelpCenter::Suggestions::ArticleSuggestions': typeof ArticleSuggestions;
    'knowledge-hub/content-editor/article-content/side-panel/help-center/suggestions/article-suggestions': typeof ArticleSuggestions;
  }
}
