/* import __COLOCATED_TEMPLATE__ from './copilot-state.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { AiContentState } from 'embercom/lib/ai-content-library/constants';

interface Args {
  copilotStates: AiContentState[];
}

export default class CopilotState extends Component<Args> {
  stateWhenUsed = AiContentState.USED_BY_FIN;
  get hasStates() {
    return this.args.copilotStates && this.args.copilotStates.length > 0;
  }
  // returning true since there isn't any pre-condition for success state
  get preCondition(): boolean {
    return true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::CopilotState': typeof CopilotState;
    'knowledge-hub/filterable-list/table/cells/copilot-state': typeof CopilotState;
  }
}
