/* import __COLOCATED_TEMPLATE__ from './side-drawer-body.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import type ContentStatistic from 'embercom/models/outbound/content-statistic';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { type StatisticKeyName } from 'embercom/models/data/outbound/types';
import { type ActiveContent } from 'embercom/components/knowledge-hub/content-editor/shared/reporting-section';

// TODO: let's use StatisticKeyName instead of just number elsewhere
// currently the components operates using statistic type number, but using StatisticKeyName is safer
const statisticValuesToKeys = Object.entries(statisticKeys).reduce(
  (acc: { [x: number]: StatisticKeyName }, [key, value]: [StatisticKeyName, number]) => {
    acc[value] = key;
    return acc;
  },
  {},
);

export type EnumeratedStat = { key: number; object: ContentStatistic | undefined };

interface Args {
  stats: EnumeratedStat[];
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
  onClose: () => void;
  activeContent: ActiveContent;
}

export default class SideDrawerBody extends Component<Args> {
  get selectedStatKeyName() {
    return statisticValuesToKeys[this.args.selectedTab];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::ReportingSection::SideDrawerBody': typeof SideDrawerBody;
    'knowledge-hub/content-editor/shared/reporting-section/side-drawer-body': typeof SideDrawerBody;
  }
}
