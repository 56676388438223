/* import __COLOCATED_TEMPLATE__ from './audience-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type ArticleContent from 'embercom/models/articles/article-content';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  activeContent: ArticleContent;
}

export default class AudienceSection extends Component<Args> {
  @service declare helpCenterService: $TSFixMe;
  @service declare intl: IntlService;
  @tracked ruleset: $TSFixMe;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.ruleset = this.args.activeContent.article.ruleset;
  }

  get hasHelpCenter() {
    return this.helpCenterService.allSites.length > 0;
  }

  get article() {
    return this.args.activeContent.article;
  }

  get helpCenterPrivacySettingsImageUrl(): string {
    return assetUrl(`/assets/images/knowledge-hub/content/help-center-privacy-settings.png`);
  }

  get segmentedContentTurnedOff() {
    return this.helpCenterService.allSites.every((site: any) => !site.segmentedContentTurnedOn);
  }

  get userTypeDescription() {
    let userTypeFromAudiencePreview =
      this.ruleset.get('audiencePreview.predicateGroup.userType') ||
      this.ruleset.get('audiencePreview.rolePredicateGroup.userType');
    let userType = userTypeFromAudiencePreview || this.ruleset.userType || 'user';
    userType = ['user', 'lead'].includes(userType) ? userType : 'person';
    return this.intl.t(
      `knowledge-hub.content-editor.article-content.side-panel.help-center-audience.userTypes.${userType}`,
      { count: this.ruleset.audiencePreview.count },
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::SidePanel::HelpCenter::Audience::AudienceSection': typeof AudienceSection;
    'knowledge-hub/content-editor/article-content/side-panel/help-center/audience/audience-section': typeof AudienceSection;
  }
}
