/* import __COLOCATED_TEMPLATE__ from './audience-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import { isAIAgentEntityType, isAICopilotEntityType } from 'embercom/lib/knowledge-hub/constants';
import { action } from '@ember/object';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';

interface Signature {
  Args: {
    activeContent: LocalizedKnowledgeContent;
  };
}

export default class AudienceSection extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;

  get sectionTitle() {
    return this.appService.app.canUseAudienceForCopilot
      ? this.intl.t('knowledge-hub.content-editor.shared.audience-section.fin-audience')
      : this.intl.t('knowledge-hub.content-editor.shared.audience-section.title');
  }

  get sectionDescription() {
    if (
      isAICopilotEntityType(this.args.activeContent.entityType) &&
      !isAIAgentEntityType(this.args.activeContent.entityType)
    ) {
      return this.intl.t(
        'knowledge-hub.content-editor.shared.audience-section.fin-audience-description.copilot',
      );
    }
    // Show copy that applies to both Fin AI Agent and AI Copilot
    return this.intl.t(
      'knowledge-hub.content-editor.shared.audience-section.fin-audience-description.all',
    );
  }

  @action trackSegmentChangeAnalytics() {
    this.knowledgeHubEditorService.trackAnalyticsEvent('updated_fin_audience');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::AudienceSection': typeof AudienceSection;
    'knowledge-hub/content-editor/shared/audience-section': typeof AudienceSection;
  }
}
