/* import __COLOCATED_TEMPLATE__ from './stats-body.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type StatisticKeyName } from 'embercom/models/data/outbound/types';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { _makeStatsParametersForArticle } from 'embercom/helpers/stats-system/stats-parameters';
import type StatsParameters from 'embercom/objects/stats-system/stats-parameters';
import { EntityType } from 'embercom/models/data/entity-types';
import type IntlService from 'ember-intl/services/intl';
import type ArticleContent from 'embercom/models/articles/article-content';
import { type ActiveContent } from 'embercom/components/knowledge-hub/content-editor/shared/reporting-section';

const COMPONENT_MAPS: Partial<{ [x in StatisticKeyName]: string }> = {
  articleContentView: 'stats-system/list/article-views-list',
  replies: 'stats-system/list/reply-list',
  reactions: 'stats-system/list/reaction-list',
};

const STATS_FUNCTION_MAPS: {
  [x: number]: (bodyStats: BodyStatsParameters) => StatsParameters;
} = {
  [EntityType.ArticleContent]: (params: BodyStatsParameters): StatsParameters => {
    let { content, range, timezone, localizedContentId, helpCenterId } = params;

    return _makeStatsParametersForArticle(
      (content as any).article,
      range,
      timezone,
      localizedContentId,
      helpCenterId,
    );
  },
};

const STATS_LOCALES_MAP: {
  [x: number]: (content: any, helpCenterService: any) => OptionItem[];
} = {
  [EntityType.ArticleContent]: (content: any, helpCenterService: any) => {
    let articleContent = content as ArticleContent;
    let localizedContents = articleContent.article.articleContents;

    return localizedContents.map((articleContent) => ({
      value: articleContent.id,
      text: helpCenterService.getLocaleNameFromLocaleCode(articleContent.locale),
    }));
  },
};

const STATS_SHOW_HELP_CENTER_MAP: {
  [x: number]: (helpCenterService: any) => OptionItem[];
} = {
  [EntityType.ArticleContent]: (helpCenterService: any) => {
    return helpCenterService.allSites.map((site: any) => ({
      value: site.id,
      text: site.name,
    }));
  },
};

type OptionItem = { value: string; text: string };

interface Signature {
  Element: HTMLDivElement;
  Args: {
    statKeyName: StatisticKeyName;
    activeContent: ActiveContent;
  };
}

interface BodyStatsParameters {
  content: ActiveContent;
  range?: $TSFixMe;
  timezone?: $TSFixMe;
  localizedContentId?: number;
  helpCenterId?: string;
}

export default class StatsBody extends Component<Signature> {
  @service declare appService: any;
  @service declare helpCenterService: any;
  @service declare intl: IntlService;

  @tracked range: $TSFixMe;
  @tracked reaction?: string;
  @tracked localizedContentId?: number;
  @tracked helpCenterId = '';

  componentMaps = COMPONENT_MAPS;

  get statsParameters(): StatsParameters | undefined {
    let statsFunction = STATS_FUNCTION_MAPS[this.args.activeContent.entityType];
    if (!statsFunction) {
      return undefined;
    }

    return statsFunction({
      content: this.args.activeContent,
      range: this.range,
      timezone: this.appService.app.timezone,
      localizedContentId: this.localizedContentId,
      helpCenterId: this.helpCenterId,
    });
  }

  // date filter

  @action onUpdateDateRange(range: $TSFixMe) {
    this.range = range;
  }

  // reaction filter

  @action changeReactionFilter(_: any, value?: string) {
    this.reaction = value;
  }

  // locale filter

  @action filterByLocalizedContentId(localizedContentId: number) {
    this.localizedContentId = localizedContentId;
  }

  get showLocaleFilter(): boolean {
    return !!STATS_LOCALES_MAP[this.args.activeContent.entityType];
  }

  get localeOptions(): OptionItem[] {
    if (!this.showLocaleFilter) {
      return [];
    }

    let getLocale = STATS_LOCALES_MAP[this.args.activeContent.entityType];
    return [
      {
        value: '',
        text: this.intl.t('articles.show.stats.locale-filter.all'),
      },
      ...getLocale(this.args.activeContent, this.helpCenterService),
    ];
  }

  // help center filter

  @action filterByHelpCenterId(helpCenterId: string) {
    this.helpCenterId = helpCenterId;
  }

  get showHelpCenterFilter(): boolean {
    return (
      !!STATS_SHOW_HELP_CENTER_MAP[this.args.activeContent.entityType] &&
      this.helpCenterService.allSites.length > 1
    );
  }

  get helpCenterOptions(): { value: string; text: string }[] {
    if (!this.showHelpCenterFilter) {
      return [];
    }

    let getHelpCenter = STATS_SHOW_HELP_CENTER_MAP[this.args.activeContent.entityType];
    return [
      {
        value: '',
        text: this.intl.t('articles.show.stats.help-center-filter.all'),
      },
      ...getHelpCenter(this.helpCenterService),
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::StatsBody': typeof StatsBody;
    'knowledge-hub/content-editor/shared/stats-body': typeof StatsBody;
  }
}
