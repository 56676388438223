/* import __COLOCATED_TEMPLATE__ from './visibility-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import { EntityType } from 'embercom/models/data/entity-types';
import type IntlService from 'ember-intl/services/intl';
import { AiContentState } from 'embercom/lib/ai-content-library/constants';
import { trackedFunction } from 'ember-resources/util/function';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import type ArticleContent from 'embercom/models/articles/article-content';

interface Args {
  activeContent: LocalizedKnowledgeContent;
  setCopilotAvailability?: (available: boolean) => any;
  setChatbotAvailability?: (available: boolean) => any;
}

const ARTICLE_LINK =
  'https://www.intercom.com/help/en/articles/9459957-enable-or-disable-content-for-fin-ai-agent-and-ai-copilot';

export default class VisibilitySection extends Component<Args> {
  @service declare appService: any;
  @service declare intercomEventService: $TSFixMe;
  @service declare permissionsService: any;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare knowledgeHubEditorService: any;

  get descriptionText() {
    if (this.args.activeContent.entityType === EntityType.ArticleContent) {
      let isMultilingual = (this.args.activeContent as ArticleContent).hasSiblingTranslations;
      if (isMultilingual) {
        return this.intl.t(
          'knowledge-hub.content-editor.article-content.visibility-section.description-multlingual',
        );
      } else {
        return this.intl.t(
          'knowledge-hub.content-editor.article-content.visibility-section.description-default',
        );
      }
    } else {
      return this.intl.t('knowledge-hub.content-editor.shared.visibility-section.description');
    }
  }

  get chatbotTooltipContent() {
    if (this.isInternalArticle) {
      return this.intl.t(
        'knowledge-hub.content-editor.shared.visibility-section.chatbot-disabled-for-internal-article',
      );
    } else {
      return this.intl.t('knowledge-hub.content-editor.shared.visibility-section.toggle-tooltip', {
        link: ARTICLE_LINK,
        htmlSafe: true,
      });
    }
  }

  get copilotTooltipContent() {
    return this.intl.t('knowledge-hub.content-editor.shared.visibility-section.toggle-tooltip', {
      link: ARTICLE_LINK,
      htmlSafe: true,
    });
  }

  get copilotState() {
    return this.isAvailableToCopilot && !this.isDisabledForCopilot
      ? AiContentState.USED_BY_FIN
      : AiContentState.NOT_USED_BY_FIN;
  }

  get chatbotState() {
    return this.isAvailableToChatbot && !this.isDisabledForChatbot
      ? AiContentState.USED_BY_FIN
      : AiContentState.NOT_USED_BY_FIN;
  }

  get isAvailableToCopilot() {
    return this.args.activeContent.availableToCopilot;
  }

  get isAvailableToChatbot() {
    return this.args.activeContent.availableToChatbot;
  }

  isEligibleForCopilot = trackedFunction(this, async () => {
    return await this.args.activeContent?.isEligibleForCopilot();
  });

  isEligibleForChatbot = trackedFunction(this, async () => {
    return await this.args.activeContent?.isEligibleForChatbot();
  });

  get isDisabledForCopilot() {
    return !this.isEligibleForCopilot.value ?? true;
  }

  get isDisabledForChatbot() {
    return !this.isEligibleForChatbot.value ?? true;
  }

  get isEnabledForCopilot() {
    return this.args.activeContent?.isEligibleAndAvailableToCopilot.value ?? false;
  }

  get isEnabledForChatbot() {
    return this.args.activeContent?.isEligibleAndAvailableToChatbot.value ?? false;
  }

  get copilotStampText() {
    return this.isEnabledForCopilot
      ? this.intl.t('knowledge-hub.content-editor.shared.visibility-section.copilot-toggle.enabled')
      : this.intl.t(
          'knowledge-hub.content-editor.shared.visibility-section.copilot-toggle.disabled',
        );
  }

  get chatbotStampText() {
    return this.isEnabledForChatbot
      ? this.intl.t('knowledge-hub.content-editor.shared.visibility-section.chatbot-toggle.enabled')
      : this.intl.t(
          'knowledge-hub.content-editor.shared.visibility-section.chatbot-toggle.disabled',
        );
  }

  get copilotStampColor(): 'sky' | 'gray' {
    return this.isEnabledForCopilot ? 'sky' : 'gray';
  }

  get chatbotStampColor(): 'sky' | 'gray' {
    return this.isEnabledForChatbot ? 'sky' : 'gray';
  }

  get isInternalArticle() {
    return this.args.activeContent.entityType === EntityType.InternalArticle;
  }

  get isArticleContent() {
    return this.args.activeContent.entityType === EntityType.ArticleContent;
  }

  get showTooltipForChatbot() {
    return this.isArticleContent || this.isInternalArticle;
  }

  get showTooltipForCopilot() {
    return this.isArticleContent;
  }

  async checkPermissionWithPopup() {
    await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
  }

  @action
  async toggleCopilotVisibility() {
    try {
      await this.checkPermissionWithPopup();
    } catch (e) {
      return;
    }

    try {
      this.knowledgeHubEditorService.trackAnalyticsEvent('toggled_copilot_visibility');
      this.knowledgeHubEditorService.trackAnalyticsEvent(
        this.isEnabledForCopilot ? 'disabled_copilot_availability' : 'enabled_copilot_availability',
      );

      if (this.args.setCopilotAvailability) {
        this.args.setCopilotAvailability(!this.isEnabledForCopilot);
      } else {
        let confirmationMessage = this.args.activeContent.availableToCopilot
          ? this.intl.t('knowledge-hub.content-editor.shared.visibility-section.copilot-disabled')
          : this.intl.t('knowledge-hub.content-editor.shared.visibility-section.copilot-enabled');
        await this.args.activeContent.toggleCopilotAvailability();
        this.notificationsService.notifyConfirmation(confirmationMessage);
      }
      await this.knowledgeHubEditorService.reloadActiveContent();
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.content-editor.shared.visibility-section.copilot-error'),
      );
    }
  }

  @action
  async toggleChatbotVisibility() {
    try {
      await this.checkPermissionWithPopup();
    } catch (e) {
      return;
    }

    try {
      this.knowledgeHubEditorService.trackAnalyticsEvent('toggled_chatbot_visibility');
      this.knowledgeHubEditorService.trackAnalyticsEvent(
        this.isEnabledForChatbot ? 'disabled_chatbot_availability' : 'enabled_chatbot_availability',
      );

      if (this.args.setChatbotAvailability) {
        this.args.setChatbotAvailability(!this.isEnabledForChatbot);
      } else {
        let confirmationMessage = this.args.activeContent.availableToChatbot
          ? this.intl.t('knowledge-hub.content-editor.shared.visibility-section.chatbot-disabled')
          : this.intl.t('knowledge-hub.content-editor.shared.visibility-section.chatbot-enabled');
        await this.args.activeContent.toggleChatbotAvailability();
        this.notificationsService.notifyConfirmation(confirmationMessage);
      }
      await this.knowledgeHubEditorService.reloadActiveContent();
    } catch (error) {
      console.error('Error', error);
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.content-editor.shared.visibility-section.chatbot-error'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::VisibilitySection': typeof VisibilitySection;
    'knowledge-hub/content-editor/shared/visibility-section': typeof VisibilitySection;
  }
}
