/* import __COLOCATED_TEMPLATE__ from './line-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ContentStatistic from 'embercom/models/outbound/content-statistic';
import type IntlService from 'ember-intl/services/intl';
import {
  STATISTIC_KEY_TO_AGGREGATION_VALUES,
  STATISTIC_KEY_TO_DESCRIPTION_KEY,
} from 'embercom/components/knowledge-hub/content-editor/shared/reporting-section';
import { action } from '@ember/object';
import { statisticKeys } from 'embercom/models/data/outbound/constants';

type AggregationItem = {
  value: string;
  count: number;
};

interface Signature {
  Element: HTMLDivElement;
  Args: {
    key: number;
    contentStat: ContentStatistic | undefined;
    setSelectedTab: (key: number) => void;
    openDrawer: (context: string) => void;
  };
}

export default class LineItem extends Component<Signature> {
  @service declare intl: IntlService;

  get eventNameFromStatKey() {
    switch (this.args.key) {
      case statisticKeys.articleContentView:
        return 'view_stat_link';
      case statisticKeys.replies:
        return 'conversation_stat_link';
      case statisticKeys.reactions:
        return 'reaction_stat_link';
      default:
        return 'view_stat_link';
    }
  }

  @action
  openDrawerWithTabSelected() {
    this.args.setSelectedTab(this.args.key);
    this.args.openDrawer(this.eventNameFromStatKey);
  }

  get description() {
    if (!STATISTIC_KEY_TO_DESCRIPTION_KEY[this.args.key]) {
      return '-';
    }

    return this.intl.t(STATISTIC_KEY_TO_DESCRIPTION_KEY[this.args.key]);
  }

  get value() {
    return this.args.contentStat?.value ?? '-';
  }

  get hasAggregationValues() {
    return (
      !!STATISTIC_KEY_TO_AGGREGATION_VALUES[this.args.key] &&
      STATISTIC_KEY_TO_AGGREGATION_VALUES[this.args.key].length > 0
    );
  }

  get subaggregations() {
    let aggregationValues = STATISTIC_KEY_TO_AGGREGATION_VALUES[this.args.key];
    if (!aggregationValues) {
      return [];
    }

    let denominator = this.args.contentStat?.value ?? 0;

    return aggregationValues.map((value) => {
      let count = 0;
      if (denominator > 0) {
        let aggregatedCount =
          this.availableAggregations.find((aggregation) => aggregation.value === value)?.count ?? 0;
        count = Math.round((aggregatedCount * 100) / denominator);
      }
      return { value, count };
    });
  }

  get availableAggregations(): AggregationItem[] {
    if (!this.args.contentStat || !Array.isArray(this.args.contentStat.subaggregations)) {
      return [];
    }

    return this.args.contentStat.subaggregations as AggregationItem[];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::ReportingSection::LineItem': typeof LineItem;
    'knowledge-hub/content-editor/shared/reporting-section/line-item': typeof LineItem;
  }
}
